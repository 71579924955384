<style scoped>
    .home-view{
        background-color: rgba(255, 255, 255, 0.3);
        background: url('@/assets/images/phone-bg.png')no-repeat center center;
        background-size: cover;
    }
    .logo-text{color:#fff; background: linear-gradient(180deg,#ed5938 50%, #ba0806 100%); border-radius:50px;font-size: small;font-weight: bold; }
    .redbtn{background: url('@/assets/images/redbtn.png')no-repeat center center; background-size: cover;}
    .redbtn-name-container{
        perspective: 200px;
    }
    .redbtn-name{
        color:#ce9a3c; 
        font-size: xxx-large;
        font-weight: 900;
        transform:rotateX(30deg);
        text-shadow:0 3px #660d08;
    }
    .redbtn-name::before{
        content:attr(text);
        mask-image: linear-gradient(180deg, #fffddb 0%, #fffddb 15%, transparent 100%);
        color:#fff;
        position: absolute;
    }
    .redbtn-en{
        color:#ffda69; 
        font-weight: 900;
        text-shadow:0 3px #660d08;
    }
    .nianbg{
        background: url('@/assets/images/p20nianbg.png')no-repeat center top; 
        background-size: cover;
    }
    .ensmall{
        font-size:xx-small;
    }
    .loginbtn{
        color:#cf281a; 
        background-image:-webkit-linear-gradient(top,#e85134,#bf0f0a);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
        font-weight:bold;
    }
    .logoname{
        color:#991905; 
        font-weight:bold;
        font-size: 0.6em;
    }
    .logoname-en{
        color:#991905; 
        font-weight:bold;
        font-size: 0.5em;
    }
    .quanqiutext{
        /* color:#cf281a; 
        background-image:-webkit-linear-gradient(top,#ffe845,#e49231);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent; */
    }
    .quanqiutext-name{
        color:#e19d56; 
        text-shadow:0 3px #9c6a6b;
    }
    .quanqiutext-name::before{
        content:attr(text);
        mask-image: linear-gradient(180deg, #fffddb 0%, #fffddb 15%, transparent 100%);
        color:#f6e979;
        position: absolute;
    }
    .jiami{
        font-size: 0.8em;
    }
    .jiami-en{
        font-size: 0.5em;
    }
    .web-title1{
        color:#f9f3a1; 
        background-image:-webkit-linear-gradient(top,#fffcde,#f3ec6c);
        -webkit-background-clip:text;
        -webkit-text-fill-color:transparent;
    }
    .hover-scale:hover{
        animation: hover-scale .7s ease infinite alternate;
    }
    .web-foot-bg{
        color: #f9c69a;
        background:linear-gradient(180deg,#f33639 10%,#b60810 100%) ;
        border-radius:50px;
        border-image: linear-gradient(to bottom, #f4d6b8, #f4d6b8) 4;
        border:3px solid;
    }
    .foot-cn{
        font-weight: bold;
        font-size: 1em;
        letter-spacing: 3px;
    }
    .foot-en{
        font-size: 0.5em;
    }
    .zxkf{
        border-radius:1rem;width:5rem;height:5rem;padding:0 1rem;
    }
    .zxkf-text{
        color: #fff09b;
    }
    .zx-time{
        font-size:.55rem;background:linear-gradient(0deg,#fceede,#fff,#fceede);color:#a2281f
    }
    .pztj{
        transform:translate(-100%, 0);box-shadow:-0.05rem 0.05rem .15rem #9d171a;background:linear-gradient(0deg,#fceede,#fff,#fceede);color:#a2281f;
        z-index: -1;
    }
    @keyframes hover-scale{
        from{
            transform: scale(1);
        }
        to{
            transform: scale(1.2);
        }
    }
    @media (max-width:575px) {
        .lh-xs-1 {
            line-height: 1 !important;
        }
    }
    @media (min-width: 576px){
        .home-view{
            background: none;
        }
		.web-bg{
			background-color: rgba(255, 255, 255, 0.3);
            background: url('@/assets/images/web-bg.png')no-repeat center center;
            background-size: cover;
		}
        .jiami{
            font-size: 1em;
        }
        .jiami-en{
            font-size: 0.65em;
        }
        .foot-cn{
            font-size: 0.8em;  
            letter-spacing: 1px; 
        }
        .foot-en{
            font-size: 0.3em;
        }
        .zxkf{
            border-radius:0.9rem;width:4rem;height:3.8rem;padding:0 .55rem;
        }
        .zxkf-text{
            font-size: 0.8em; 
            letter-spacing: 3px;
        }
        .zx-time{
            font-size: 0.4em; 
        }
        .pztj{
            font-size: 0.4em; 
        }
	}
	@media (min-width: 768px){
		.foot-cn{
            font-size: 1.1em;  
            letter-spacing: 1px; 
        }
        .foot-en{
            font-size: 0.5em;
        }
        .zxkf{
            border-radius:1.2rem;width:5.4rem;height:5rem;padding:0 .55rem;
        }
        .zxkf-text{
            font-size: 1.15em; 
            letter-spacing: 3px;
        }
        .zx-time{
            font-size: 0.6em; 
        }
        .pztj{
            font-size: 0.6em; 
        }
        .logoname{
            font-size: 0.8em;
        }
        .logoname-en{
            font-size: 0.7em;
        }
	}
	@media (min-width: 992px){
		.foot-cn{
            font-size: 1.55em;  
            letter-spacing: 3px; 
        }
        .foot-en{
            font-size: 0.7em;
        }
        .zxkf{
            border-radius:2rem;width:7.4rem;height:7.2rem;padding:0 1rem;
        }
        .zxkf-text{
            font-size: 1.55em; 
            letter-spacing: 4px;
        }
        .zx-time{
            font-size: 0.8em; 
        }
        .pztj{
            font-size: 0.8em; 
        }
        .logoname{
            font-size: 1.2em;
            padding-left: 1.5rem;
        }
        .logoname-en{
            font-size: 1.2em;
            padding-left: 1rem;
        }
	}
	@media (min-width: 1200px){
		.foot-cn{
            font-size: 1.9em;  
            letter-spacing: 3px; 
        }
        .foot-en{
            font-size: 0.9em;
        }
        .zxkf{
            border-radius:2rem;width:8.6rem;height:8rem;padding:0 1rem;
        }
        .zxkf-text{
            font-size: 1.9em; 
            letter-spacing: 4px;
        }
        .zx-time{
            font-size: 0.9em; 
        }
	}
	@media (min-width: 1400px){
		.foot-cn{
            font-size: 2.2em;  
            letter-spacing: 4px; 
        }
        .foot-en{
            font-size: 1em;
        }
        .zxkf{
            border-radius:2rem;width:9rem;height:8.6rem;padding:0 1rem;
        }
        .zxkf-text{
            font-size: 2.2em; 
            letter-spacing: 4px;
        }
        .zx-time{
            font-size: 1em; 
        }
	}
</style>
<template>
    <div class="w-100 d-flex flex-column home-view">
		<!-- 第一版 -->
        <div class="d-flex position-relative d-sm-none ">
            <img class="mx-auto img-fluid" src="@/assets/images/phonedt.png"/>
            <div class="container-sm w-100 h-100 position-absolute d-flex flex-column justify-content-between">
                <div class="py-2 ps-sm-3 d-flex flex-column align-items-end text-center ">
                    <img class="me-4" style="max-width:5.8rem" src="images/logo.png"/>
                    <span class="logo-text p-1 ps-2 pe-2">全球华人娱乐场所</span>
                </div>
                <div class="p-2 pb-4 d-flex flex-column position-relative align-items-center">
                    <img style="max-width:20.8rem" src="@/assets/images/redbtn.png"/>
                    <div class=" d-flex flex-column position-absolute ">
                        <div class="redbtn-name-container">
                            <h1 class="redbtn-name m-0 pt-1" :text="config.brand.nameCN">{{config.brand.nameCN}}</h1>
                        </div>
                        <span class="redbtn-en">{{config.brand.nameEN}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第一版电脑版 -->
        <div class="d-none d-sm-flex position-relative ">
            <video class="w-100 h-100" muted type="video/mp4" autoplay loop src="@/assets/images/web-video.mp4"></video>
            <!-- <img class="mx-auto img-fluid" src="@/assets/images/web-top.jpg"/> -->
            <div class="container w-100 h-100 position-absolute d-flex flex-column justify-content-center">
                <div class="position-absolute top-0 py-2 d-flex flex-column align-items-start text-center ">
                    <img class="me-4" style="max-width:5.8rem" src="images/logo.png"/>
                    <span class="logo-text p-1 ps-2 pe-2 ">全球华人娱乐场所</span>
                </div>
                <div class="d-flex flex-column position-absolute align-items-start ps-5">
                    <div class="fw-bold web-title1 fs-1">二十年实体品牌</div>
                    <div class="fw-bold web-title1-en fs-6 " style="color:#f5f5f5;">Twenty year physical brand</div>
                    <div class="fw-bold web-title2 fs-1 pt-1" style="color:#efc436;">全球首创  先发牌  后下注</div>
                    <div class="fw-bold web-title1-en" style="color:#f5f5f5;">Global Initiative, First Deal, Second Bet</div>
                </div>
            </div>
        </div>
        <!-- 第二版 -->
		<div class="flex-grow-1 py-3 py-sm-4 d-sm-none">
			<div class="h-100">
				<div class="">
					<div class="col-12 col-xl-4 mb-3 mb-sm-4  nianbg">
						<div class="d-flex justify-content-center rounded py-2 px-3 p-sm-4" >
							
							<div class="d-flex flex-column justify-content-between text-nowrap">
								<div class="fw-bold fs-2" style="color:#e2221e;">二十年实体品牌</div>
								<div class="" style="color:#a1641c;">Twenty year physical brand</div>
								<div class="fw-bold fs-2" style="color:#b81715;">全球首创  先发牌  后下注</div>
								<div class="mb-2" style="color:#a1641c;">Global Initiative, First Deal, Second Bet</div>
								
							</div>
						</div>
						<div class="d-flex justify-content-start rounded py-1 px-3 p-sm-4" >
							<div class="d-flex flex-column justify-content-between text-start">
								<div class="small">三分钟极速出款  纯白资  包安全</div>
                                <div class="small">大额无忧   为您提供</div>
                                <div class="small">最完美的线上VIP尊尚娱乐体验</div>
								<div class="ensmall">Fast withdrawals in just three minutes,</div>
                                <div class="ensmall">pure white funds, guaranteed safety, </div>
                                <div class="ensmall">worry-free large amounts, </div>
                                <div class="ensmall">providing you with the ultimate VIP online </div>
                                <div class="ensmall mb-2">entertainment experience.</div>
							</div>
						</div>
                        <div class="d-flex justify-content-start rounded pt-2 pb-1 px-3" >
                            <div class="pb-4 d-flex flex-column position-relative">
                                <img style="max-width:11.8rem" src="@/assets/images/h5login.png"/>
                                <div class="w-100 d-flex flex-column position-absolute  text-center">
                                    <div class="d-flex flex-column" style="cursor: pointer;" @click="goLink(config.url.h5)">
                                        <h5 class="p-0 m-0 pt-1 loginbtn">H5安全登录</h5>
                                        <span class="small loginbtn">Secure Login</span>
                                    </div>
                                    
                                </div>
                            </div>
						</div>
					</div>
                    
				</div>

			</div>
		</div>
        <div class="web-bg">
            
            <!-- 第三版 -->
            <div class="container-sm">
                <!-- 招牌 -->
                <div class="col-12 mb-sm-4">
                    <div class="d-flex justify-content-center rounded pt-2 pb-1 px-3" >
                        <div class="pb-1 d-flex flex-column position-relative w-75">
                            <img class="img-fluid" src="@/assets/images/gametype.png"/>
                            <div class="w-100 d-flex ps-2 flex-column position-absolute text-center" style="top:25%">
                                <div class=" d-flex flex-column ">
                                    <span class="ps-lg-4 ps-sm-2 ps-xs-2 m-0 logoname lh-xs-1">龙源国际</span>
                                    <span class="logoname-en lh-xs-1">-- Longyuan International --</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 图标 -->
                <div class="d-flex flex-wrap px-1 ">
                    <div class="col-6 col-sm-4 mb-3 mb-sm-4 px-1 px-sm-3">
                        <img class="img-fluid" src="@/assets/images/baijia.png"/>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-4 px-1 px-sm-3">
                        <img class="img-fluid" src="@/assets/images/longhu.png"/>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-4 px-1 px-sm-3">
                        <img class="img-fluid" src="@/assets/images/cow.png"/>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-4 px-1 px-sm-3">
                        <img class="img-fluid" src="@/assets/images/dezhou.png"/>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-4 px-1 px-sm-3">
                        <img class="img-fluid" src="@/assets/images/majiang.png"/>
                    </div>
                    <div class="col-6 col-sm-4 mb-3 mb-sm-4 px-1 px-sm-3">
                        <img class="img-fluid" src="@/assets/images/lucktiger.png"/>
                    </div>
                </div>
            </div>
            <!-- 第四版 -->
            <div class="container-sm">
                <div class="d-flex justify-content-center rounded py-2 px-3 p-sm-4" >
                    <div class="d-flex flex-column justify-content-between text-nowrap align-items-center">
                        <div class="fw-bold fs-6" style="color:#8b4b13;">更多精彩游戏，尽在龙源国际</div>
                        <div class="fw-bold small" style="color:#8b4b13;">More exciting games, all at Longyuan International</div>
                        <div class="mb-4 mt-sm-2 d-flex flex-column position-relative align-items-center hover-scale overflow-hidden">
                            <img style="max-width:13.8rem" src="@/assets/images/h5login.png"/>
                            <div class="w-100 d-flex flex-column position-absolute  text-center">
                                <div class="d-flex flex-column" style="cursor: pointer;" @click="goLink(config.url.h5)">
                                    <h5 class="p-0 m-0 pt-1 pt-sm-2 loginbtn">H5安全登录</h5>
                                    <span class="small loginbtn">Secure Login</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 黄色图标 -->
                <div class="d-flex flex-wrap px-1 px-sm-5">
                    <div class="col-6 col-sm-3 mb-3 mb-sm-4 px-2 hover-scale" style="cursor: pointer;" @click="goLink(config.url.tel)">
                        <img class="img-fluid" src="@/assets/images/dianhua.png"/>
                    </div>
                    <div class="col-6 col-sm-3 mb-3 mb-sm-4 px-2 hover-scale" style="cursor: pointer;" @click="goLink(config.url.browser)">
                        <img class="img-fluid" src="@/assets/images/liulanqi.png"/>
                    </div>
                    <div class="col-6 col-sm-3 mb-3 mb-sm-4 px-2 hover-scale" style="cursor: pointer;" @click="goLink(config.url.app)">
                        <img class="img-fluid" src="@/assets/images/app.png"/>
                    </div>
                    <div class="col-6 col-sm-3 mb-3 mb-sm-4 px-2 hover-scale" style="cursor: pointer;" @click="goLink(config.url.chat)">
                        <img class="img-fluid" src="@/assets/images/liaotian.png"/>
                    </div>
                </div>
                <!-- 货币文字 -->
                <div class="d-flex justify-content-center rounded py-2 px-3 p-sm-4" >
                    <div class="d-flex flex-column justify-content-between text-nowrap">
                        <div class="fw-bold display-4">
                            <span class="quanqiutext-name m-0 pt-1" text="全球通用货币充值">全球通用货币充值</span>
                        </div>
                        <div class="fw-bold fs-6 " style="color:#8b4b13;">Global Currency Recharge</div>
                        <div class="fw-bold jiami pt-1" style="color:#8b4b13;">加密数字货币·纯白资·包安区·大额无忧</div>
                        <div class="fw-bold jiami-en" style="color:#8b4b13;">Cryptocurrency · Pure White Funds · Secure Zone · Worry-Free Large Amounts</div>
                    </div>
                </div>
                <!-- 支付图标 -->
                <div class="d-flex d-sm-none flex-wrap px-1 pt-2">
                    <div class="col mb-3 mb-sm-4 mx-1">
                        <img class="img-fluid" src="@/assets/images/weixin.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1">
                        <img class="img-fluid" src="@/assets/images/zhifubao.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1">
                        <img class="img-fluid" src="@/assets/images/bank.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1">
                        <img class="img-fluid" src="@/assets/images/usdt.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1">
                        <img class="img-fluid" src="@/assets/images/dianzi.png"/>
                    </div>
                </div>
                <!-- 支付图标电脑版 -->
                <div class="d-none d-sm-flex flex-wrap px-1 pt-2  px-sm-5" >
                    <div class="col mb-3 mb-sm-4 mx-1 px-2">
                        <img class="img-fluid" src="@/assets/images/web-weixin.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1 px-2">
                        <img class="img-fluid" src="@/assets/images/web-zhifubao.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1 px-2">
                        <img class="img-fluid" src="@/assets/images/web-bank.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1 px-2">
                        <img class="img-fluid" src="@/assets/images/web-usdt.png"/>
                    </div>
                    <div class="col mb-3 mb-sm-4 mx-1 px-2">
                        <img class="img-fluid" src="@/assets/images/web-dianzi.png"/>
                    </div>
                </div>
                <!-- 底部客服图标 -->
                <div class="d-flex flex-wrap px-1 pt-2" style="cursor: pointer;" @click="goLink(config.url.chat)">
                    <div class="col d-sm-none">
                        <img class="img-fluid" src="@/assets/images/footkf.png"/>
                    </div>
                    <div class="col d-none d-sm-flex px-sm-5 flex-column justify-content-between">
                        <!-- <img class="img-fluid" src="@/assets/images/webfoot.png"/> -->
                        <div class="d-flex flex-column position-relative ">
                            <div class="d-flex">
                                <img class="img-fluid ms-4" style="width:13%" src="@/assets/images/web-footlogo.png"/>
                            </div>
                            <div class="web-foot-bg d-flex flex-column justify-content-between align-items-start py-2">
                                <span class="foot-cn ps-3 ps-lg-5 ps-sm-4">
                                    二十年实体品牌·全球华人娱乐场所
                                </span>
                                <span class="foot-en ps-5 ms-5">
                                    TWENTY YEARS OF PHYSICAL BRAND · GLOBAL CHINESE ENTERTAINMENT VENUES
                                </span>
                            </div>
                            <div class="position-absolute end-0 top-50 translate-middle">
                                <div class="position-relative web-foot-bg fw-bold d-flex flex-column justify-content-center zxkf" style="">
                                    <span class="hover-scale zxkf-text">咨询客服</span>
                                    <div class="position-absolute w-100 start-0 top-100 translate-middle-y bg-light rounded-pill py-1 zx-time" style="">24小时在线</div>
                                    <div class="position-absolute rounded-end rounded-pill text-nowrap start-0 py-1 py-lg-2 px-3 px-sm-2 px-lg-4 small pztj" style="">品质推荐 千万人好评+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
	</div>
</template>
<script>
    export default {
        data(){
            return {
                config:window.config
            }
        },
        methods:{
            /**
             * 跳转到客服链接
             */
            toChat(url){
                window.open(url,'_blank');
            },
            goLink(url){
                window.location.href=url;
            }
        }
    }
</script>