import { createRouter, createWebHashHistory } from 'vue-router'
import PromotionView from '../views/PromotionView.vue'
import Home from '../views/home.vue'

const routes = [
  {
    path: '/promotion',
    name: 'promotion',
    component: PromotionView
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
